
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { Empreendimento } from '@/core/models/cadastros';
import { EmpreendimentoService } from '@/core/services/cadastros';

@Component
export default class ListaEmpreendimento extends mixins(Vue,ListPage) {
  service = new EmpreendimentoService();
  item = new Empreendimento();

  empreendimento = new Empreendimento();

  titulo: string = 'Empreendimento';
  subTitulo: string = 'Lista dos Empreendimentos para o uso no sistema';
  
  overlay = false;
  loading = false;
  dialogCadastro = false;
  dialogVisualizarMapa = false;
  
  options: any = { 
    itemsPerPage: 15
  } 
  
  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: 'Nome', value: 'nome' },   
    { text: 'Qtde de unidades', value: 'quantidadeUnidade', sortable: false }
  ];

   breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Lista de Empreendimentos', disabled: true, href: '/financeiro/painel/despesas'}
  ]

  @Watch("options", { deep: true })
  Atualizar() { 
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true; 

    this.service.Listar(page,itemsPerPage,sortBy,sortDesc,search, columns, undefined, '', 'id, nome,quantidadeUnidade', 'Grupos.Unidades').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count; 
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }).finally(() => (this.loading = false));
  } 

  Novo(){
    this.empreendimento = new Empreendimento(); 
    this.dialogCadastro = true;
  }
  
  Gerenciar(item) {
    this.overlay = true;
    this.service.ObterPorId(item.id, 'Proprietarios, LadosAdicionaisPadroes, Fotos').then(  
      (res) => {
        this.empreendimento = new Empreendimento(res.data);
        this.dialogCadastro = true
        this.overlay = false;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      })
  } 

  VisualizarMapa(item){
    this.overlay = true;
    this.service.ObterPorId(item.id, 'Grupos.Unidades').then( 
      res => {
        this.item = res.data;
        this.dialogVisualizarMapa = true;
        this.overlay = false;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      })
  }
} 
